import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "What is hypertension?",
    body: "Hypertension, or high blood pressure, is a condition in which the force of blood against the walls of your arteries is consistently too high.",
  },
  {
    id: 1,
    title: "What are the symptoms of hypertension?",
    body: "Hypertension often has no symptoms, which is why it's known as the Silent Killer. However, in some cases, you may experience headaches, shortness of breath, dizziness, or nosebleeds.",
  },
  {
    id: 3,
    title: "What causes hypertension?",
    body: "There are many factors that can contribute to hypertension, including age, family history, obesity, lack of exercise, and unhealthy diet.",
  },
  {
    id: 4,
    title: "What is considered high blood pressure?",
    body: "High blood pressure is typically defined as a reading of 130/80 mm Hg or higher.",
  },
  {
    id: 5,
    title: "How is hypertension diagnosed?",
    body: "Hypertension is diagnosed through a simple and painless test called a blood pressure check.",
  },
  {
    id: 6,
    title: "What are the complications of hypertension?",
    body: "Complications of hypertension can include heart disease, stroke, kidney disease, and vision loss.",
  },
  {
    id: 7,
    title: "How can I prevent hypertension?",
    body: "You can reduce your risk of hypertension by maintaining a healthy weight, exercising regularly, eating a healthy diet, limiting alcohol intake, and not smoking.",
  },
  {
    id: 8,
    title:"How is hypertension treated?",
    body: "Hypertension can be treated with lifestyle changes, such as exercise and diet modification, as well as medication prescribed by your doctor.",
  },
  {
    id: 9,
    title:"What lifestyle changes can help manage hypertension?",
    body: "Lifestyle changes that can help manage hypertension include exercising regularly, eating a healthy diet that's low in salt and rich in fruits and vegetables, limiting alcohol intake, and quitting smoking.",
  },
  {
    id: 10,
    title: "Can hypertension be cured?",
    body: "While hypertension cannot be cured, it can be effectively managed with the right treatment plan and lifestyle changes.",
  },
  {
    id: 11,
    title:"What medications are used to treat hypertension?",
    body: "There are several different classes of medications used to treat hypertension, including diuretics, beta-blockers, ACE inhibitors, and calcium channel blockers.",
  },
  {
    id: 12,
    title:"How often should I check my blood pressure?",
    body: "It's recommended that you check your blood pressure at least once a year, or more frequently if you have hypertension or are at risk of developing it.",
  },
  {
    id: 13,
    title:"Can stress cause hypertension?",
    body: "Yes, stress can contribute to hypertension, especially if it's chronic or unmanaged.",
  },
  {
    id: 14,
    title:"How does diet affect hypertension?",
    body: "A diet that's high in salt, saturated fat, and processed foods can contribute to hypertension, while a diet that's rich in fruits, vegetables, and whole grains can help manage it.",
  },
  {
    id: 15,
    title:"What is the DASH diet and how can it help manage hypertension?",
    body: "The DASH diet is a dietary approach to stop hypertension, which emphasizes fruits, vegetables, whole grains, lean protein, and low-fat dairy. It can help manage hypertension by reducing salt intake and improving overall nutrition.",
  },
  {
    id: 16,
    title:"Can hypertension be inherited?",
    body: "Yes, hypertension can run in families, but it's not solely determined by genetics.",
  },
  {
    id: 17,
    title:"How does age affect hypertension?",
    body: "Hypertension becomes more common with age, as the arteries become less flexible and more prone to plaque buildup.",
  },
  {
    id: 18,
    title:"How does pregnancy affect hypertension?",
    body: "Pregnancy can cause a temporary form of hypertension known as gestational hypertension, which typically resolves after delivery. However, some women may develop preeclampsia, a more severe form of hypertension that can be dangerous for both the mother and baby.",
  },
  {
    id: 19,
    title:"What is the difference between primary and secondary hypertension?",
    body: "Primary hypertension is the most common form of hypertension, and it has no identifiable cause. Secondary hypertension is caused by an underlying medical condition, such as kidney disease or thyroid problems.",
  },
  {
    id: 20,
    title:"Can hypertension lead to a heart attack or stroke?",
    body: "Yes, hypertension is a major risk factor for heart attack and stroke, which is why monitoring is key",
  },
]
export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions our clients ask about Hypertension</h1>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
